import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import PortableTextBlock from '@components/sanity/portableTextBlock';

const SimplePage = ({ data: { page } }) => {
  const { config, seo, title, text } = page || {};
  return (
    <Layout {...config}>
      <PageMeta {...seo} />
      <div className="px-gutter pt-space-m pb-space-s text-center grid gap-y-[18px]">
        <h1 className="text-3xl text-red md:text-5xl lg:text-6xl">{title}</h1>
      </div>
      <div className="px-gutter pb-space-m">
        <PortableTextBlock text={text} className="max-w-6xl mx-auto" />
      </div>
    </Layout>
  );
};

export default SimplePage;

export const pageQuery = graphql`
  query basicPageQuery($slug: String!) {
    page: sanitySimplePage(slug: { current: { eq: $slug } }) {
      title
      seo {
        ...SEO
      }
      config {
        ...PageSettings
      }
      text: _rawText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
